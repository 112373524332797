import { createSvgIcon } from "@mui/material/utils";

export const PyPiIcon = createSvgIcon(
  <svg
    width="20"
    height="18"
    viewBox="0 0 20 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_7_4)">
      <path
        d="M2.90869 6.77918L5.72946 7.80585L8.59197 6.76398L5.77121 5.7373L2.90869 6.77918Z"
        fill="#F7F7F4"
      />
      <path
        d="M5.79719 5.66614H5.74545L2.88293 6.70801V6.85017L5.7037 7.87685H5.75545L8.61796 6.83498V6.69281L5.79719 5.66614ZM5.77126 5.81756L8.37068 6.76372L5.7295 7.7253L3.13008 6.77885L5.77126 5.81756Z"
        fill="#CCCCCC"
      />
      <path
        d="M2.90869 6.77917L5.72946 7.80585V11.1212L2.90869 10.0945V6.77917Z"
        fill="#EFEEEA"
      />
      <path
        d="M2.93465 6.70801L2.83313 6.77909V10.0944L2.8829 10.1655L5.70367 11.1922L5.80519 11.1211V7.80577L5.75541 7.73468L2.93465 6.70801ZM2.9842 6.88686L5.65382 7.85843V11.0131L2.9842 10.0412V6.88686Z"
        fill="#CCCCCC"
      />
      <path
        d="M5.74182 4.46118L8.56259 5.48786L11.4251 4.44599L8.60433 3.41931L5.74182 4.46118Z"
        fill="#F7F7F4"
      />
      <path
        d="M8.6302 3.34814H8.57846L5.71594 4.39002V4.53218L8.53671 5.55886H8.58845L11.451 4.51699V4.37482L8.6302 3.34814ZM8.6042 3.49952L11.2036 4.44568L8.56214 5.40726L5.96241 4.46111L8.6042 3.49952Z"
        fill="#CCCCCC"
      />
      <path
        d="M5.74182 4.46118L8.56259 5.48786V8.80318L5.74182 7.7765V4.46118Z"
        fill="#EFEEEA"
      />
      <path
        d="M5.76765 4.39001L5.66614 4.4611V7.77641L5.71591 7.8475L8.53668 8.87417L8.63819 8.80309V5.48777L8.58842 5.41669L5.76765 4.39001ZM5.81714 4.56882L8.48677 5.54069V8.69504L5.81714 7.72347V4.56882Z"
        fill="#CCCCCC"
      />
      <path
        d="M0.0756836 11.1575L2.89645 12.1841L5.75897 11.1423L2.9382 10.1156L0.0756836 11.1575Z"
        fill="#F7F7F4"
      />
      <path
        d="M2.96406 10.0446H2.91232L0.0498047 11.0864V11.2286L2.87057 12.2553H2.92232L5.78483 11.2134V11.0712L2.96406 10.0446ZM2.93822 10.1958L5.53795 11.142L2.89616 12.1036L0.296737 11.1574L2.93822 10.1958Z"
        fill="#CCCCCC"
      />
      <path
        d="M0.0756836 11.1575L2.89645 12.1841V15.4995L0.0756836 14.4728V11.1575Z"
        fill="#EFEEEA"
      />
      <path
        d="M0.101516 11.0864L0 11.1575V14.4728L0.0497721 14.5439L2.87054 15.5706L2.97206 15.4995V12.1842L2.92228 12.1131L0.101516 11.0864ZM0.151166 11.2654L2.82049 12.237V15.3914L0.151166 14.4198V11.2654Z"
        fill="#CCCCCC"
      />
      <path
        d="M2.90869 12.1877L5.72946 13.2144V16.5297L2.90869 15.5031V12.1877Z"
        fill="#EFEEEA"
      />
      <path
        d="M2.93465 12.1167L2.83313 12.1878V15.5031L2.8829 15.5742L5.70367 16.6009L5.80519 16.5298V13.2145L5.75541 13.1434L2.93465 12.1167ZM2.98422 12.2955L5.65384 13.2673V16.4217L2.98422 15.4501V12.2955Z"
        fill="#CCCCCC"
      />
      <path
        d="M2.90869 8.8396L5.72946 9.86628L8.59197 8.82441L5.77121 7.79773L2.90869 8.8396Z"
        fill="#F7F7F4"
      />
      <path
        d="M5.79707 7.72656H5.74533L2.88281 8.76843V8.9106L5.70358 9.93727H5.75532L8.61784 8.8954V8.75324L5.79707 7.72656ZM5.77115 7.8781L8.37057 8.82426L5.72939 9.78554L3.12997 8.83939L5.77115 7.8781Z"
        fill="#CCCCCC"
      />
      <path
        d="M2.90869 8.8396L5.72946 9.86628V13.1816L2.90869 12.1549V8.8396Z"
        fill="#EFEEEA"
      />
      <path
        d="M2.93465 8.76843L2.83313 8.83951V12.1548L2.8829 12.2259L5.70367 13.2526L5.80519 13.1815V9.86619L5.75541 9.79511L2.93465 8.76843ZM2.98422 8.9474L5.65384 9.91897V13.0733L2.98422 12.1018V8.9474Z"
        fill="#CCCCCC"
      />
      <path
        d="M17.0619 11.1539V14.4692L19.9244 13.4274V10.1121L17.0619 11.1539Z"
        fill="white"
      />
      <path
        d="M19.8985 10.041L17.036 11.0829L16.9862 11.154V14.4693L17.0877 14.5404L19.9502 13.4985L20 13.4274V10.1121L19.8985 10.041ZM19.8485 10.2201V13.3744L17.1375 14.3611V11.2067L19.8485 10.2201Z"
        fill="#CCCCCC"
      />
      <path
        d="M14.2288 12.1842V15.4995L17.0913 14.4577V11.1423L14.2288 12.1842Z"
        fill="#FFD242"
      />
      <path
        d="M17.0654 11.0713L14.2028 12.1132L14.1531 12.1842V15.4996L14.2546 15.5706L17.1171 14.5288L17.1669 14.4577V11.1424L17.0654 11.0713ZM17.0155 11.2501V14.4047L14.3041 15.3914V12.2371L17.0155 11.2501Z"
        fill="#CCCCCC"
      />
      <path
        d="M11.3956 13.2145V16.5298L14.2581 15.4879V12.1726L11.3956 13.2145Z"
        fill="#FFD242"
      />
      <path
        d="M14.2324 12.1014L11.3698 13.1433L11.3201 13.2144V16.5297L11.4216 16.6008L14.2841 15.5589L14.3339 15.4878V12.1725L14.2324 12.1014ZM14.1825 12.2803V15.4346L11.4711 16.4216V13.2673L14.1825 12.2803Z"
        fill="#CCCCCC"
      />
      <path
        d="M8.56262 14.2446V17.5599L11.4251 16.5181V13.2028L8.56262 14.2446Z"
        fill="white"
      />
      <path
        d="M11.3992 13.1317L8.53671 14.1736L8.48694 14.2447V17.56L8.58845 17.6311L11.451 16.5892L11.5007 16.5181V13.2028L11.3992 13.1317ZM11.3492 13.3106V16.465L8.6381 17.452V14.2973L11.3492 13.3106Z"
        fill="#CCCCCC"
      />
      <path
        d="M5.74182 13.2179L8.56259 14.2446V17.5599L5.74182 16.5332V13.2179Z"
        fill="#EFEEEA"
      />
      <path
        d="M5.76765 13.1469L5.66614 13.2179V16.5333L5.71591 16.6043L8.53668 17.631L8.63819 17.5599V14.2446L8.58842 14.1735L5.76765 13.1469ZM5.81715 13.3257L8.48678 14.2973V17.4519L5.81715 16.48V13.3257Z"
        fill="#CCCCCC"
      />
      <path
        d="M17.0619 7.80579V11.1211L19.9244 10.0792V6.76392L17.0619 7.80579Z"
        fill="#FFD242"
      />
      <path
        d="M19.8985 6.69287L17.036 7.73474L16.9862 7.80582V11.1211L17.0877 11.1922L19.9502 10.1504L20 10.0793V6.76395L19.8985 6.69287ZM19.8485 6.8718V10.0261L17.1375 11.0131V7.85849L19.8485 6.8718Z"
        fill="#CCCCCC"
      />
      <path
        d="M14.2411 3.43091V6.74623L17.1036 5.70436V2.38904L14.2411 3.43091Z"
        fill="#353564"
      />
      <path
        d="M17.0777 2.31799L14.2152 3.35987L14.1654 3.43095V6.74627L14.2669 6.81735L17.1294 5.77548L17.1792 5.70439V2.38908L17.0777 2.31799ZM17.0279 2.49685V5.6512L14.3165 6.6382V3.48385L17.0279 2.49685Z"
        fill="#CCCCCC"
      />
      <path
        d="M14.2411 3.43091L17.0619 4.45759L19.9244 3.41571L17.1036 2.38904L14.2411 3.43091Z"
        fill="#FFC91D"
      />
      <path
        d="M17.1295 2.31799H17.0777L14.2152 3.35987V3.50203L17.036 4.52871H17.0877L19.9502 3.48683V3.34467L17.1295 2.31799ZM17.1036 2.46932L19.7036 3.41547L17.0618 4.37705L14.4624 3.4309L17.1036 2.46932Z"
        fill="#CCCCCC"
      />
      <path
        d="M17.0619 4.45764V7.77296L19.9244 6.73109V3.41577L17.0619 4.45764Z"
        fill="#FFD242"
      />
      <path
        d="M19.8985 3.3446L17.036 4.38648L16.9862 4.45756V7.77288L17.0877 7.84396L19.9502 6.80209L20 6.731V3.41569L19.8985 3.3446ZM19.8485 3.52342V6.67807L17.1375 7.66477V4.51042L19.8485 3.52342Z"
        fill="#CCCCCC"
      />
      <path
        d="M14.2288 8.83606V12.1514L17.0913 11.1095V7.79419L14.2288 8.83606Z"
        fill="#FFD242"
      />
      <path
        d="M17.0654 7.72302L14.2028 8.76489L14.1531 8.83598V12.1513L14.2546 12.2224L17.1171 11.1805L17.1669 11.1094V7.7941L17.0654 7.72302ZM17.0155 7.902V11.0564L14.3041 12.0431V8.8887L17.0155 7.902Z"
        fill="#CCCCCC"
      />
      <path
        d="M14.2288 5.48779V8.80311L17.0913 7.76124V4.44592L14.2288 5.48779Z"
        fill="#3775A9"
      />
      <path
        d="M17.0654 4.37488L14.2028 5.41675L14.1531 5.48783V8.80315L14.2546 8.87423L17.1171 7.83236L17.1669 7.76128V4.44596L17.0654 4.37488ZM17.0155 4.55375V7.7081L14.3041 8.6951V5.54075L17.0155 4.55375Z"
        fill="#CCCCCC"
      />
      <path
        d="M11.408 1.11292L14.2287 2.13959L17.0912 1.09772L14.2705 0.0710449L11.408 1.11292Z"
        fill="#2F6491"
      />
      <path
        d="M14.2963 0H14.2446L11.3821 1.04187V1.18404L14.2028 2.21071H14.2546L17.1171 1.16884V1.02668L14.2963 0ZM14.2702 0.15127L16.8696 1.09773L14.2284 2.05901L11.629 1.11285L14.2702 0.15127Z"
        fill="#CCCCCC"
      />
      <path
        d="M14.2288 2.13965V5.45497L17.0913 4.4131V1.09778L14.2288 2.13965Z"
        fill="#3775A9"
      />
      <path
        d="M17.0654 1.02673L14.2028 2.0686L14.1531 2.13969V5.45501L14.2546 5.52609L17.1171 4.48422L17.1669 4.41313V1.09782L17.0654 1.02673ZM17.0155 1.2058V4.36015L14.3041 5.34685V2.1925L17.0155 1.2058Z"
        fill="#CCCCCC"
      />
      <path
        d="M8.57495 12.1549L11.3957 13.1816L14.2582 12.1397L11.4375 11.113L8.57495 12.1549Z"
        fill="#AFAFDE"
      />
      <path
        d="M11.4633 11.0419H11.4116L8.54907 12.0837V12.2259L11.3698 13.2526H11.4216L14.2841 12.2107V12.0685L11.4633 11.0419ZM11.4373 11.1934L14.0367 12.1396L11.3955 13.1008L8.79607 12.1547L11.4373 11.1934Z"
        fill="#CCCCCC"
      />
      <path
        d="M11.3956 9.86621V13.1815L14.2581 12.1397V8.82434L11.3956 9.86621Z"
        fill="#FFD242"
      />
      <path
        d="M14.2324 8.7533L11.3698 9.79517L11.3201 9.86625V13.1816L11.4216 13.2526L14.2841 12.2108L14.3339 12.1397V8.82438L14.2324 8.7533ZM14.1825 8.93233V12.0867L11.4711 13.0734V9.91903L14.1825 8.93233Z"
        fill="#CCCCCC"
      />
      <path
        d="M11.3956 6.51807V9.83339L14.2581 8.79151V5.4762L11.3956 6.51807Z"
        fill="#3775A9"
      />
      <path
        d="M14.2324 5.40503L11.3698 6.4469L11.3201 6.51798V9.8333L11.4216 9.90438L14.2841 8.86251L14.3339 8.79143V5.47611L14.2324 5.40503ZM14.1825 5.58396V8.73831L11.4711 9.72501V6.57066L14.1825 5.58396Z"
        fill="#CCCCCC"
      />
      <path
        d="M8.60437 8.82788L11.4251 9.85456V13.1699L8.60437 12.1432V8.82788Z"
        fill="#E9E9FF"
      />
      <path
        d="M8.6302 8.75684L8.52869 8.82792V12.1432L8.57846 12.2143L11.3992 13.241L11.5007 13.1699V9.85459L11.451 9.78351L8.6302 8.75684ZM8.67985 8.93567L11.3492 9.90724V13.0619L8.67985 12.09V8.93567Z"
        fill="#CCCCCC"
      />
      <path
        d="M8.56262 10.8965V14.2118L11.4251 13.1699V9.85461L8.56262 10.8965Z"
        fill="#3775A9"
      />
      <path
        d="M11.3992 9.78345L8.53671 10.8253L8.48694 10.8964V14.2117L8.58845 14.2828L11.451 13.2409L11.5007 13.1698V9.85453L11.3992 9.78345ZM11.3492 9.96224V13.1169L8.6381 14.1036V10.9492L11.3492 9.96224Z"
        fill="#CCCCCC"
      />
      <path
        d="M5.74182 9.86975L8.56259 10.8964V14.2117L5.74182 13.1851V9.86975Z"
        fill="#2F6491"
      />
      <path
        d="M5.76765 9.79871L5.66614 9.86979V13.1851L5.71591 13.2562L8.53668 14.2829L8.63819 14.2118V10.8965L8.58842 10.8254L5.76765 9.79871ZM5.81715 9.97773L8.48678 10.9493V14.1037L5.81715 13.1321V9.97773Z"
        fill="#CCCCCC"
      />
      <path
        d="M5.74182 6.52161L8.56259 7.54828L11.4251 6.50641L8.60433 5.47974L5.74182 6.52161Z"
        fill="#2F6491"
      />
      <path
        d="M8.6302 5.40857H8.57846L5.71594 6.45044V6.5926L8.53671 7.61928H8.58845L11.451 6.57741V6.43525L8.6302 5.40857ZM8.60421 5.56006L11.2036 6.50621L8.56245 7.4675L5.96303 6.52134L8.60421 5.56006Z"
        fill="#CCCCCC"
      />
      <path
        d="M8.56262 7.54822V10.8635L11.4251 9.82167V6.50635L8.56262 7.54822Z"
        fill="#3775A9"
      />
      <path
        d="M11.3992 6.4353L8.53671 7.47717L8.48694 7.54826V10.8636L8.58845 10.9347L11.451 9.89279L11.5007 9.8217V6.50638L11.3992 6.4353ZM11.3492 6.61429V9.76864L8.6381 10.7553V7.60099L11.3492 6.61429Z"
        fill="#CCCCCC"
      />
      <path
        d="M5.74182 6.52161L8.56259 7.54828V10.8636L5.74182 9.83693V6.52161Z"
        fill="#2F6491"
      />
      <path
        d="M5.76765 6.45044L5.66614 6.52152V9.83684L5.71591 9.90792L8.53668 10.9346L8.63819 10.8635V7.5482L8.58842 7.47712L5.76765 6.45044ZM5.81715 6.62936L8.48678 7.60093V10.7553L5.81715 9.78371V6.62936Z"
        fill="#CCCCCC"
      />
      <path
        d="M8.57495 2.14319V5.45851L11.4375 4.41664V1.10132L8.57495 2.14319Z"
        fill="#353564"
      />
      <path
        d="M11.4116 1.03027L8.54904 2.07215L8.49927 2.14323V5.45855L8.60078 5.52963L11.4633 4.48776L11.5131 4.41667V1.10136L11.4116 1.03027ZM11.3616 1.20913V4.36348L8.6505 5.35048V2.19614L11.3616 1.20913Z"
        fill="#CCCCCC"
      />
      <path
        d="M8.57495 2.14319L11.3957 3.16987L14.2582 2.128L11.4375 1.10132L8.57495 2.14319Z"
        fill="#2F6491"
      />
      <path
        d="M11.4633 1.03027H11.4116L8.54907 2.07215V2.21431L11.3698 3.24099H11.4216L14.2841 2.19911V2.05695L11.4633 1.03027ZM11.4376 1.1816L14.0367 2.12775L11.3955 3.08934L8.79607 2.14318L11.4376 1.1816Z"
        fill="#CCCCCC"
      />
      <path
        d="M11.3956 3.1698V6.48512L14.2581 5.44325V2.12793L11.3956 3.1698Z"
        fill="#3775A9"
      />
      <path
        d="M14.2324 2.05688L11.3698 3.09876L11.3201 3.16984V6.48516L11.4216 6.55624L14.2841 5.51437L14.3339 5.44329V2.12797L14.2324 2.05688ZM14.1825 2.23571V5.39036L11.4711 6.37706V3.22271L14.1825 2.23571Z"
        fill="#CCCCCC"
      />
      <path
        d="M8.57495 2.14319L11.3957 3.16986V6.48518L8.57495 5.45851V2.14319Z"
        fill="#2F6491"
      />
      <path
        d="M8.60078 2.07214L8.49927 2.14323V5.45854L8.54904 5.52963L11.3698 6.5563L11.4713 6.48522V3.1699L11.4216 3.09882L8.60078 2.07214ZM8.6505 2.2512L11.3198 3.22277V6.37712L8.6505 5.40555V2.2512Z"
        fill="#CCCCCC"
      />
      <path
        d="M16.0227 13.5776C16.3263 13.5776 16.5724 13.4053 16.5724 13.1927C16.5724 12.9801 16.3263 12.8077 16.0227 12.8077C15.719 12.8077 15.4729 12.9801 15.4729 13.1927C15.4729 13.4053 15.719 13.5776 16.0227 13.5776Z"
        fill="white"
      />
      <path
        d="M12.4643 4.8197C12.7679 4.8197 13.0141 4.64735 13.0141 4.43475C13.0141 4.22215 12.7679 4.0498 12.4643 4.0498C12.1607 4.0498 11.9146 4.22215 11.9146 4.43475C11.9146 4.64735 12.1607 4.8197 12.4643 4.8197Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_7_4">
        <rect width="20" height="17.6562" fill="white" />
      </clipPath>
    </defs>
  </svg>,
  "PyPiIcon"
);
